
<template>
	<div>
		<v-btn class="button" @click="returnToPortCalls"> Return to Port calls </v-btn>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:externalFilter="filter()"
			:navigableDetail="false"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:externalFilter="filter()"
			:navigableDetail="false"
			:parentModelName="parentModelName"
			:modelColumnDefs="modelColumnDefs"
			:readOnly="true"
			:modalDialog="modalDialog"
		></pui-datatable>
	</div>
</template>


<script>
export default {
	name: 'shipagencytransfers-grid',
	components: {},
	data() {
		return {
			modelName: 'shipagencytransfers',
			modelColumnDefs: {}
		};
	},
	methods: {
		returnToPortCalls() {
			const params = {
				router: this.$router,
				url: `/portcalls`,
				params: null,
				query: {},
				replace: false
			};
			this.$store.dispatch('puiRouterGoToUrl', params);
		},
		filter() {
			let portid = localStorage.getItem('portcallid');

			let groups = [];
			let filter = null;

			groups.push({
				groupOp: 'and',
				groups: [],
				rules: [{ field: 'portcallid', op: 'eq', data: portid }]
			});

			filter = { groupOp: 'or', rules: [], groups: groups };

			localStorage.setItem('portcallid', null);
			return filter;
		}
	}
};
</script>

<style>
.button {
	background-color: #2f6383 !important;
	border-color: #2f6383 !important;
	color: white !important;
	padding: 8px 8px !important;
	text-align: center !important;
	text-decoration: none !important;
	display: inline-block !important;
	font-size: 14px !important;
	cursor: pointer !important;
	margin-left: 14px !important;
	margin-top: 20px !important;
	border-radius: 5px !important;
}
</style>
